import { useQuery } from '@tanstack/react-query'

import { getOrderTemplateViews, GetOrderTemplateViewsParams } from '../api'
import { ORDER_TEMPLATE_VIEWS_QUERY_KEY } from '../constants'

export const useOrderTemplateViewsQuery = (
  params: GetOrderTemplateViewsParams,
) => {
  const { data, ...rest } = useQuery({
    queryKey: [ORDER_TEMPLATE_VIEWS_QUERY_KEY, params],
    queryFn: () => getOrderTemplateViews(params),
    enabled: (params.projectIds || []).length > 0,
  })

  return { templateTypes: data?.types || [], ...rest }
}
