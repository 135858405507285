import { withStyles } from '@bruitt/classnames'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { NotificationItem } from '../../../../entities/notification/components/notification-item'
import { NotificationLink } from '../../../../entities/notification/components/notification-link'
import { useReadAllNotification } from '../../../../entities/notification/use-read-all-notifications'
import { useReadNotification } from '../../../../entities/notification/use-read-notification'
import { useUnreadNotificationCount } from '../../../../entities/notification/use-unread-notification-count'
import { isEqualDay, parseDate } from '../../../../shared/lib/dates'
import { Card } from '../../../../shared/uikit/card'
import { Flex } from '../../../../shared/uikit/flex'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { LinkButton } from '../../../../shared/uikit/link-button'
import { Text } from '../../../../shared/uikit/text'
import { useInfiniteNotifications } from '../../use-infinite-notifications'

import s from './notification-list.module.scss'

const sx = withStyles(s)

interface NotificationListProps {
  onClose: () => void
  listClassName?: string
  onClickNotification?: () => void
}

export const NotificationList = ({
  onClose,
  listClassName,
  onClickNotification,
}: NotificationListProps) => {
  const { pages, fetchNextPage, isFetched } = useInfiniteNotifications()
  const { count } = useUnreadNotificationCount()
  const { readNotification } = useReadNotification()
  const { readAllNotifications } = useReadAllNotification()

  const { ref, inView } = useInView({
    rootMargin: isFetched ? '500px' : '200px',
  })

  useEffect(() => {
    if (inView && isFetched) {
      void fetchNextPage()
    }
  }, [inView, fetchNextPage, isFetched])

  return (
    <>
      <Flex jc="space-between" ai="center" py="8" px="16" pt="20">
        <Text s="body-bold" pl="14">
          Уведомления
        </Text>
        <IconButton variant="secondary" s="s" icon="close" onClick={onClose} />
      </Flex>

      <Card.Divider />

      <Flex
        className={sx(s.list, listClassName)}
        dir="column"
        pl="16"
        pr="2"
        mr="6"
        my="20"
        g="8"
      >
        {pages.map((page) =>
          page.list?.map((n, i, list) => (
            <React.Fragment key={n.id}>
              {list[i - 1] &&
                isEqualDay(new Date(), list[i - 1]?.createdAt) &&
                !isEqualDay(new Date(), n.createdAt) && (
                  <Text s="body-bold" mt="16" pb="10">
                    Ранее
                  </Text>
                )}

              <NotificationLink
                source={n.notificationSourceType.code}
                id={n.notificationSourceId}
                onClick={onClickNotification}
              >
                <NotificationItem
                  icon={n.icon}
                  title={n.caption}
                  text={n.body}
                  date={
                    isEqualDay(new Date(), n.createdAt)
                      ? `сегодня в ${parseDate(n.createdAt, 'HH:mm')}`
                      : parseDate(n.createdAt, 'dd.MM.yyyy в HH:mm')
                  }
                  isNew={!n.readAt}
                  onClickRead={() => readNotification(n.id)}
                />
              </NotificationLink>
            </React.Fragment>
          )),
        )}

        {/* {pages.at(-1)?.pagination?.hasMore && (
          <Flex width="full" jc="center" my="16">
            <Loader.InBox color="primary-500" />
          </Flex>
        )} */}

        {pages.length > 0 && <div ref={ref} />}
      </Flex>

      <Card.Divider />

      <Flex jc="center" py="12" pb="16">
        <LinkButton
          onClick={readAllNotifications}
          disabled={!count || count === 0}
        >
          Отметить все как прочитанные
        </LinkButton>
      </Flex>
    </>
  )
}
