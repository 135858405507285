import { OrdersFilterSearch } from '../../pages/orders-page/types'
import { getEndOfDay, getStartOfDay } from '../../shared/lib/dates'
import { RentAreaListItem } from '../rent-area/types'

import {
  ChangeOrderFieldsRequest,
  GetOrdersFilter,
  OrderFieldsDto,
  OrderFilesDto,
} from './api'
import {
  EMPTY_PERMISSIONS,
  ORDER_APPROVAL_CODE,
  ORDER_STATUS_OPTIONS,
} from './constants'
import {
  Order,
  OrderSchemasDto,
  OrdersFilterFormValues,
  TemplateType,
} from './types'
import {
  CustomFieldsFilesValues,
  CustomFieldsFormValues,
  orderSettingsFormSchema,
  orderStatusesSchema,
} from './types-schema'

export const parseOrderSchema = (data?: Partial<OrderSchemasDto>) => {
  {
    if (!data) return EMPTY_SCHEMA

    try {
      const fieldsSchema = orderSettingsFormSchema.safeParse(
        JSON.parse(data.fieldsSchema || ''),
      )
      const statusesSchema = orderStatusesSchema.safeParse(
        JSON.parse(data.statusesSchema || ''),
      )

      return {
        fields: fieldsSchema.success ? fieldsSchema.data.fields : [],
        layoutDetails: fieldsSchema.success
          ? fieldsSchema.data.layoutDetails
          : [],
        layoutProcess: fieldsSchema.success
          ? fieldsSchema.data.layoutProcess
          : [],
        permissions: statusesSchema.success
          ? statusesSchema.data.statuses?.[0]?.rolePermissions[0]?.permission
          : EMPTY_PERMISSIONS,
        transitions: statusesSchema.success
          ? statusesSchema.data.statuses?.[0]?.roleTransitions[0]?.transitions
          : [],
        plannedCompletionMinutes: statusesSchema.success
          ? statusesSchema.data.statuses?.[0]?.plannedCompletionMinutes
          : undefined,
      }
    } catch (error) {
      return EMPTY_SCHEMA
    }
  }
}

const EMPTY_SCHEMA = {
  fields: [],
  layoutDetails: [],
  layoutProcess: [],
  permissions: EMPTY_PERMISSIONS,
  transitions: [],
  plannedCompletionMinutes: undefined,
}

export const parseOrderFormValuesToUpdateRequest = (
  orderId: string,
  fieldsValues: CustomFieldsFormValues,
  filesValues: CustomFieldsFilesValues,
): ChangeOrderFieldsRequest => {
  return {
    orderId,
    fields: parseOrderFieldsToDto(fieldsValues),
    fileFields: parseOrderFilesToDto(filesValues),
  }
}

export const parseOrderFieldsToDto = (
  fields: CustomFieldsFormValues,
): OrderFieldsDto[] =>
  Object.entries(fields).map(([k, values]) => ({
    fieldId: parseInt(k, 10),
    values,
  }))

export const parseOrderFilesToDto = (
  files: CustomFieldsFilesValues,
): OrderFilesDto[] =>
  Object.entries(files)
    .filter(([, files]) => files.length > 0)
    .map(([k, files]) => ({
      fieldId: parseInt(k, 10),
      files,
    }))

export const parseFilterSearchToRequestDto = (
  f: OrdersFilterSearch,
): GetOrdersFilter => {
  return {
    statuses: f.statuses?.length ? f.statuses : undefined,
    projectIds: f.projects?.length ? f.projects : undefined,
    rentAreaIds: f.rentAreas?.length ? f.rentAreas : undefined,
    typeIds: f.orderTypes?.length
      ? f.orderTypes.map((ot) => parseInt(ot, 10))
      : undefined,
    plannedDateFilter: {
      start: f.slaFrom ? getStartOfDay(f.slaFrom).toISOString() : undefined,
      end: f.slaTo ? getEndOfDay(f.slaTo).toISOString() : undefined,
    },
    createdDateFilter: {
      start: f.createdFrom
        ? getStartOfDay(f.createdFrom).toISOString()
        : undefined,
      end: f.createdTo ? getEndOfDay(f.createdTo).toISOString() : undefined,
    },
  }
}

export const parseFilterFormValuesToFilterSearch = (
  values: OrdersFilterFormValues,
): OrdersFilterSearch => {
  return {
    statuses: values.statuses?.map(({ value }) => value),
    projects: values.projects?.map(({ id }) => id),
    rentAreas: values.rentAreas?.map(({ rentAreaId }) => rentAreaId),
    orderTypes: values.orderTypes?.map(({ id }) => id.toString()),
    slaFrom: values.slaFrom,
    slaTo: values.slaTo,
    createdFrom: values.createdFrom,
    createdTo: values.createdTo,
  }
}

interface ParseFilterSearchToFormValuesParams {
  filter: OrdersFilterSearch
  rentAreas: RentAreaListItem[]
  projects: RentAreaListItem['project'][]
  templateTypes: TemplateType[]
}

export const parseFilterSearchToFormValues = ({
  filter: f,
  rentAreas,
  projects,
  templateTypes,
}: ParseFilterSearchToFormValuesParams): OrdersFilterFormValues => {
  return {
    statuses:
      f.statuses
        ?.map((s) => ORDER_STATUS_OPTIONS.find((o) => o.value === s))
        .filter(Boolean) || [],
    projects:
      f.projects
        ?.map((p) => projects.find((pr) => pr.id === p))
        .filter(Boolean) || [],
    rentAreas:
      f.rentAreas
        ?.map((r) => {
          const { rentAreaId, unit } = rentAreas.find(
            (ra) => ra.rentAreaId === r,
          )!
          return { rentAreaId, unit }
        })
        .filter(Boolean) || [],
    orderTypes:
      f.orderTypes
        ?.map((ot) => templateTypes.find((o) => o.id.toString() === ot))
        .filter(Boolean) || [],
    slaFrom: f.slaFrom || '',
    slaTo: f.slaTo || '',
    createdFrom: f.createdFrom || '',
    createdTo: f.createdTo || '',
  }
}

export const getIsApprovalOrder = (order?: Order): boolean => {
  return order?.type.code === ORDER_APPROVAL_CODE
}
