import * as R from 'rambda'
import { useMemo } from 'react'

import { useResponsive } from '../../../../shared/hooks/use-responsive'
import { FileDetails } from '../../../../shared/types/api-types'
import { Box } from '../../../../shared/uikit/box'
import { ErrorText } from '../../../../shared/uikit/error-text'
import { Grid } from '../../../../shared/uikit/grid'
import { Text } from '../../../../shared/uikit/text'
import { CustomField } from '../../../order/components/custom-field'
import { EMPTY_PERMISSIONS } from '../../constants'
import {
  CustomFieldsFilesValues,
  CustomFieldsFormValues,
  OrderRolePermission,
  OrderSettingsField,
  OrderSettingsLayout,
} from '../../types-schema'

interface CustomFieldsFormProps {
  orderId?: string
  fields?: OrderSettingsField[]
  layout?: OrderSettingsLayout
  permissions?: OrderRolePermission
  values: CustomFieldsFormValues
  files: CustomFieldsFilesValues
  remoteFiles?: CustomFieldsFormValues<FileDetails>
  errors?: { [key: string]: string }
  showInvisibleFields?: boolean
  onChangeValue?: (id: number) => (value: string[]) => void
  onChangeFiles?: (id: number) => (value: File[]) => void
}

export const CustomFieldsForm = ({
  orderId,
  fields = [],
  layout = [],
  permissions = EMPTY_PERMISSIONS,
  showInvisibleFields = false,
  values,
  files,
  remoteFiles = {},
  errors,
  onChangeValue,
  onChangeFiles,
}: CustomFieldsFormProps) => {
  const fieldsMap = R.indexBy(R.prop('id'), fields)
  const { canEdit, visible } = useMemo(
    () => ({
      canEdit: new Set(permissions.canEdit),
      visible: new Set(permissions.visible),
    }),
    [permissions],
  )
  const isDesktop = useResponsive('lg')

  return (
    <Grid g="24">
      {layout.map((group, i) => {
        const isGroupHasFields = group.fields
          .map(
            (f) =>
              Boolean(fieldsMap[f.id]) &&
              (showInvisibleFields ? true : visible.has(f.id)),
          )
          .some(Boolean)

        return isGroupHasFields ? (
          <Grid
            key={i}
            // @ts-ignore
            cols={isDesktop ? group.columns.toString() : '1'}
            g="12"
          >
            {group.title && (
              // @ts-ignore
              <Box span={group.columns.toString()}>
                <Text s="sub-headline-bold">{group.title}</Text>
              </Box>
            )}

            {group.fields.map((f) => {
              const field = fieldsMap[f.id]

              if (!field) return null
              if (!showInvisibleFields && !visible.has(f.id)) return null

              return (
                // @ts-ignore
                <Box key={f.id} span={f.span.toString()}>
                  <CustomField
                    orderId={orderId}
                    field={field}
                    isReadOnly={!canEdit.has(f.id)}
                    value={values[f.id]}
                    files={files[f.id]}
                    remoteFiles={remoteFiles[f.id]}
                    onChangeValue={onChangeValue?.(f.id)}
                    onChangeFiles={onChangeFiles?.(f.id)}
                  />
                  {errors?.[f.id] && <ErrorText text={errors[f.id]} />}
                </Box>
              )
            })}
          </Grid>
        ) : null
      })}
    </Grid>
  )
}
