import { NBSP } from '../../../../shared/constants/nbsp'
import { parseDate } from '../../../../shared/lib/dates'
import { Table } from '../../../../shared/uikit/table'
import { Text } from '../../../../shared/uikit/text'
import { RENT_AREA_TYPES } from '../../../rent-area/constants'
import { OrderListItem } from '../../types'
import { OrderStatusTag } from '../order-status-tag'
import s from './/order-row.module.scss'

interface OrderRowProps {
  order: OrderListItem
}

const OrderRow = ({ order: o }: OrderRowProps) => {
  return (
    <Table.Row cellClassName={s.cell} className={s.row}>
      <div>
        <Text s="footnote" mb="6">
          № {o.number.toString().padStart(7, '0')}
        </Text>
        <OrderStatusTag status={o.status} />
      </div>
      <div>
        {o.projectName}
        <br />
        {o.address?.city}
      </div>
      <div>
        {o.destinationType && RENT_AREA_TYPES[o.destinationType]} №{o.unit}
      </div>
      <div>
        {o.typeName}. {o.subtypeView?.name || o.subtypeName}
      </div>
      <div>{parseDate(o.plannedCompletionDate, 'dd.MM.yyyy в HH:mm')}</div>
      <div>{parseDate(o.createdDate, 'dd.MM.yyyy в HH:mm')}</div>
    </Table.Row>
  )
}

const Skeleton = () => {
  return (
    <Table.Row cellClassName={s.cell} className={s.skeletonWrapper}>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
      <div>
        <Text s="footnote" className={s.skeleton} rad="8">
          {NBSP}
        </Text>
      </div>
    </Table.Row>
  )
}

const OrderRowNamespace = Object.assign(OrderRow, { Skeleton })

export { OrderRowNamespace as OrderRow }
