import { useMemo } from 'react'

import { Box } from '../../../../shared/uikit/box'
import { Button } from '../../../../shared/uikit/button'
import {
  CenterModal,
  CenterModalProps,
} from '../../../../shared/uikit/center-modal'
import { Flex } from '../../../../shared/uikit/flex'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { Text } from '../../../../shared/uikit/text'
import { useChangeOrderFieldsMutation } from '../../hooks/use-change-order-fields-mutation'
import { Order } from '../../types'
import {
  OrderRolePermission,
  OrderSettingsField,
  OrderSettingsLayout,
} from '../../types-schema'
import { CustomFieldsForm } from '../custom-fields-form'
import { useCustomFieldsForm } from '../custom-fields-form/use-custom-fields-form'

import s from './order-transition-fields-form.module.scss'

interface OrderTransitionFieldsFormProps {
  isOpen: CenterModalProps['isOpen']
  onClose: () => void
  orderId: string
  order: Order
  fields?: OrderSettingsField[]
  layout?: number[]
  permissions?: OrderRolePermission
  onSuccess?: () => void
}

export const OrderTransitionFieldsForm = ({
  orderId,
  order: o,
  isOpen,
  onClose,
  fields,
  layout,
  permissions,
  onSuccess: onSuccessSubmit,
}: OrderTransitionFieldsFormProps) => {
  const initialValues = useMemo(() => {
    return o?.fields.filter((f) => layout?.includes(f.fieldId))
  }, [o?.fields, layout])

  const {
    isDirty,
    values,
    files,
    remoteFiles,
    handleChangeValue,
    handleChangeFiles,
    errors,
    handleSubmit,
    handleReset,
    onSuccess,
  } = useCustomFieldsForm({
    initialValues,
    initialFiles: o?.fileFields,
    fields: fields,
    permissions: permissions,
    onSubmit: (values, files) => {
      onSubmit(values, files)
    },
  })

  const fieldsLayout: OrderSettingsLayout = [
    {
      title: '',
      columns: 1,
      fields: layout?.map((f) => ({ id: f, span: 1 })) || [],
    },
  ]

  const { isPending, onSubmit } = useChangeOrderFieldsMutation({
    orderId,
    onSuccess: () => {
      void onSuccess?.()
      void onSuccessSubmit?.()
      void handleReset()
    },
  })

  return (
    <CenterModal isOpen={isOpen} onClose={onClose} className={s.root}>
      <Box p="24" p_md="32">
        <Flex jc="space-between">
          <Text s="title3-bold">Заполните необходимые поля</Text>
          <IconButton
            variant="secondary"
            s="l"
            icon="close"
            onClick={onClose}
          />
        </Flex>
        <Box mt="16">
          <CustomFieldsForm
            orderId={orderId}
            fields={fields}
            layout={fieldsLayout}
            permissions={permissions}
            showInvisibleFields
            values={values}
            files={files}
            remoteFiles={remoteFiles}
            onChangeValue={handleChangeValue}
            onChangeFiles={handleChangeFiles}
            errors={errors}
          />

          <Flex g="8" mt="24" mt_md="32" jc="flex-end">
            <Button variant="secondary" onClick={onClose}>
              Закрыть
            </Button>
            <Button
              variant="primary"
              isLoading={isPending}
              disabled={!isDirty}
              onClick={handleSubmit}
            >
              Продолжить
            </Button>
          </Flex>
        </Box>
      </Box>
    </CenterModal>
  )
}
