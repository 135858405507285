import { router } from '../../../../main'
import { ROUTES } from '../../../../shared/constants/routes'
import { BaseButton } from '../../../../shared/uikit/base-button'
import { NOTIFICATION_SOURCE_TYPES } from '../../constants'
import { NotificationSourceType } from '../../types'

interface NotificationLinkProps {
  source: NotificationSourceType
  id: string
  children?: React.ReactNode
  onClick?: () => void
}

export const NotificationLink = ({
  id,
  source,
  children,
  onClick,
}: NotificationLinkProps) => {
  return source === NOTIFICATION_SOURCE_TYPES.order ? (
    <BaseButton
      onClick={() => {
        onClick?.()
        void router.navigate({ to: ROUTES.orderId, params: { orderId: id } })
      }}
    >
      {children}
    </BaseButton>
  ) : (
    children
  )
}
