import { Option } from '../../shared/types/option'

import { OrdersFilterFormValues } from './types'

export const ORDER_QUERY_KEY = 'order'
export const ORDER_LIST_QUERY_KEY = 'order-list'
export const ORDER_PHOTO_QUERY_KEY = 'order-photo'
export const ORDER_TEMPLATE_VIEWS_QUERY_KEY = 'order-template-views'

export const ORDER_LIST_SORT_FIELDS = {
  createdAt: 'CreatedAt',
  // status: 'Status',
  // typeName: 'TypeName',
  // subtypeName: 'SubtypeName',
  // projectName: 'ProjectName',
  // personClientFullName: 'PersonClientFullName',
  // executorFullName: 'ExecutorFullName',
} as const

export const ORDERS_FILTER_FORM_DEFAULT_VALUES: OrdersFilterFormValues = {
  statuses: [],
  projects: [],
  rentAreas: [],
  orderTypes: [],
  slaFrom: '',
  slaTo: '',
  createdFrom: '',
  createdTo: '',
}

export const EMPTY_PERMISSIONS = {
  visible: [],
  canEdit: [],
  required: [],
}

export const ORDER_ORIGINS_FORM_VALUES: { [key: string]: string } = {
  IncomingCall: 'Входящий звонок',
  FaceToFaceVisit: 'Очный визит',
  SocialMedia: 'Социальные сети',
  Messengers: 'Мессенджеры',
  ManagingCompany: 'Обращение от УК',
}

export const ORDER_ORIGINS: { [key: string]: string } = {
  ...ORDER_ORIGINS_FORM_VALUES,
  RentMobileApplication: 'МП Арендатора',
  EmployeeMobileApplication: 'МП Сотрудника',
}

export const ORDER_SMS_RETRY_TIMEOUT_SEC = 60

export const ORDER_STATUS_OPTIONS: Option[] = [
  { value: 'Draft', label: 'Черновик' },
  { value: 'New', label: 'Новая, На согласовании' },
  { value: 'InProgress', label: 'В работе' },
  { value: 'Completed', label: 'Завершено' },
  { value: 'Approved', label: 'Согласовано' },
  { value: 'Declined', label: 'Отклонено' },
  { value: 'Canceled', label: 'Отменено' },
]

export const ORDER_APPROVAL_CODE = 'Approval'
