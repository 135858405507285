import { useQueryClient } from '@tanstack/react-query'
import { Popover } from 'react-tiny-popover'

import { useUnreadNotificationCount } from '../../../../entities/notification/use-unread-notification-count'
import { useProfilePhoto } from '../../../../entities/user/hooks/use-profile-photo-query'
import { useProfileQuery } from '../../../../entities/user/hooks/use-profile-query'
import { NotificationList } from '../../../../features/notification-list/components/notification-list'
import { useInfiniteNotifications } from '../../../../features/notification-list/use-infinite-notifications'
import { useModal } from '../../../../shared/hooks/use-modal'
import { useOpenable } from '../../../../shared/hooks/use-openable'
import { logout } from '../../../../shared/lib/auth'
import { AvatarPlaceholder } from '../../../../shared/uikit/avatar-placeholder'
import { BaseButton } from '../../../../shared/uikit/base-button'
import { Box } from '../../../../shared/uikit/box'
import { Card } from '../../../../shared/uikit/card'
import { CenterModal } from '../../../../shared/uikit/center-modal'
import { Flex } from '../../../../shared/uikit/flex'
import { FullscreenModal } from '../../../../shared/uikit/fullscreen-modal'
import { IconButton } from '../../../../shared/uikit/icon-button'
import { Text } from '../../../../shared/uikit/text'
import { Profile } from '../profile/profile'

import s from './profile-bar.module.scss'

interface ProfileBarProps {
  children?: React.ReactNode
}

export const ProfileBar = (_: ProfileBarProps) => {
  const profilePopover = useOpenable()
  const profileModal = useModal()
  const notificationsPopover = useOpenable()
  const notificationsModal = useOpenable()
  const queryClient = useQueryClient()
  const { profile } = useProfileQuery()
  const { isFetched, pages } = useInfiniteNotifications()
  const { count } = useUnreadNotificationCount()
  const { photoUrl } = useProfilePhoto(profile?.photo?.fileName)

  const handleLogout = () => {
    logout(() => setTimeout(() => queryClient.clear(), 1000))
  }

  return (
    <>
      <Flex g="24">
        <Flex g="8">
          {pages.at(0)?.list?.length ? (
            <>
              <Box hidden display_md="block">
                <Popover
                  isOpen={notificationsPopover.isOpen}
                  positions={['bottom', 'right']}
                  onClickOutside={notificationsPopover.close}
                  align="end"
                  content={
                    <Box mt="8" style={{ marginRight: -64 }}>
                      <Card className={s.card}>
                        <NotificationList
                          onClickNotification={notificationsPopover.close}
                          onClose={notificationsPopover.close}
                          listClassName={s.desktopNotificationList}
                        />
                      </Card>
                    </Box>
                  }
                >
                  <div className={s.wrapper}>
                    <IconButton
                      icon="notification-3-filled"
                      variant="ghost"
                      s="m"
                      onClick={notificationsPopover.toggle}
                      disabled={!isFetched}
                    />

                    {count ? (
                      <Text
                        s="caption2-bold"
                        color="base-0"
                        bgColor="red-400"
                        className={s.notificationCount}
                      >
                        {count}
                      </Text>
                    ) : null}
                  </div>
                </Popover>
              </Box>

              <Box hidden_md>
                <div className={s.wrapper}>
                  <IconButton
                    icon="notification-3-filled"
                    variant="ghost"
                    s="m"
                    onClick={notificationsModal.open}
                    disabled={!isFetched}
                  />

                  {count ? (
                    <Text
                      s="caption2-bold"
                      color="base-0"
                      bgColor="red-400"
                      className={s.notificationCount}
                    >
                      {count}
                    </Text>
                  ) : null}
                </div>
              </Box>
            </>
          ) : null}
        </Flex>

        <Box hidden display_md="block">
          <Popover
            isOpen={profilePopover.isOpen}
            positions={['bottom', 'right']}
            onClickOutside={profilePopover.close}
            align="end"
            content={
              <Box mt="8">
                <Profile
                  photoUrl={photoUrl}
                  profile={profile}
                  onClose={profilePopover.close}
                  onClickExit={handleLogout}
                />
              </Box>
            }
          >
            <BaseButton onClick={profilePopover.toggle}>
              <ProfilePhoto photoUrl={photoUrl} />
            </BaseButton>
          </Popover>
        </Box>

        <Box hidden_md>
          <BaseButton onClick={profileModal.toggle}>
            <ProfilePhoto photoUrl={photoUrl} />
          </BaseButton>
        </Box>
      </Flex>

      <CenterModal
        isOpen={profileModal.isOpen}
        onClose={profileModal.close}
        width="312"
      >
        <Profile
          photoUrl={photoUrl}
          profile={profile}
          onClose={profileModal.close}
          onClickExit={handleLogout}
        />
      </CenterModal>

      <FullscreenModal
        isOpen={notificationsModal.isOpen}
        onClose={notificationsModal.close}
      >
        <NotificationList
          onClickNotification={notificationsModal.close}
          onClose={notificationsModal.close}
          listClassName={s.mobileNotificationList}
        />
      </FullscreenModal>
    </>
  )
}

const ProfilePhoto = ({ photoUrl }: { photoUrl?: string }) => {
  return photoUrl ? (
    <img
      src={photoUrl}
      alt="Avatar"
      width={40}
      height={40}
      className={s.photo}
    />
  ) : (
    <AvatarPlaceholder className={s.avatar} rad="6" bgColor="base-0" />
  )
}
